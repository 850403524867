import { useTranslation } from "react-i18next";
import { ErrorOutline, Payment } from "@mui/icons-material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { dayjs } from "../../../config";
import Settings from "../../../settings";
import PaymentStatus from "../InvoicesTableComponents/PaymentStatus";
import PayButton from "../../PaymentPlatform/PayButton";
import { DownloadButton } from "../DownloadButton";
import useI18n from "@/hooks/useI18n";
import { useEffect, useState } from 'react';


// Payment provider (default is stripe)
const paymentProvider = Settings?.paymentProvider ?? "stripe";

const useInvoicesTable = ({type}) => {
  const { t } = useTranslation();
  const loaded = useSelector((state) => state.invoices[type].loaded);
  const invoices = useSelector((state) => state.invoices[type].items);
  const settings = useSelector((state) => state.settings);
  const [options, setOptions] = useState({'pdf': {name: t('common:text.invoices_view_download_button'), type: 'pdf'}});

  const { currentLang } = useI18n();

  useEffect(() => {
    if (!settings?.data?.invoice_download_types)
      return
    const settings_parsed = JSON.parse(settings?.data?.invoice_download_types.replace(/'/g, '"'));
    let optionsCopy = {...options}
    for ( let setting of settings_parsed || []) {
      if (!optionsCopy[setting]){
        let name_value = ''
        let type = ''

        if (setting === 'signed_pdf') {
            name_value = t('common:text.invoices_view_download_signed_button')
            type = 'signed_pdf'
        }
        else if (setting === 'curve') {
          name_value = t('common:text.invoices_view_download_curve')
          type = 'curve_csv'
        }

        optionsCopy[setting] = {
          name: name_value,
          type: type
        }
      }
    }
    setOptions(optionsCopy);
  }, [settings]);

  const invoiceShowTotalArray = (() => {
    try {
      return JSON.parse(settings?.data?.invoice_show_total?.replace(/'/g, '"')) || ['amount_total'];
    } catch {
      return ['amount_total'];
    }
  })();

  const columnsElectricity = [
    {
      title: t('common:text.invoices_invoice_number'),
      key: "num",
      computeValue: (invoice) => invoice.number
    }, {
      title: t('common:text.invoices_date'),
      key: "date",
      computeValue: (invoice) => dayjs(invoice.date).format("L")
    }, {
      title: t('common:text.invoices_period'),
      key: "period",
      computeValue: (invoice) => `${dayjs(invoice.start_date).format("L")} > ${dayjs(invoice.end_date).format("L")}`
    }, {
      title: t('common:text.invoices_address'),
      key: "address",
      computeValue: (invoice) => invoice.cups.full_address
    },
    invoiceShowTotalArray.includes('amount_total') && {
      title: t('common:text.invoices_import'),
      key: "import",
      computeValue: (invoice) => `${invoice.amount_total.toLocaleString(currentLang.value)} €`
    },
    invoiceShowTotalArray.includes('amount_to_pay') && {
      title: t('common:text.invoices_paying_import'),
      key: "import_to_pay",
      computeValue: (invoice) => invoice?.amount_to_pay && `${invoice.amount_to_pay.toLocaleString(currentLang.value)} €`
    }, {
      title: t('common:text.invoices_pending'),
      key: "pending",
      computeValue: (invoice) => <PaymentStatus invoice={invoice} />,
    }, {
      title: t('common:text.invoices_energy'),
      key: "energy",
      computeValue: (invoice) => `${invoice.energy_consumed.toLocaleString(currentLang.value)} kWh`
    }, {
      title: t('common:text.invoices_actions'),
      key: "actions",
      computeValue: (invoice) => <>
        <DownloadButton 
          invoiceId={invoice.id} 
          type={type} 
          options={options}
        />
        { 
          Settings.invoices?.enablePayment && 
          invoice?.online_payment?.payment_enabled && (
            <PayButton 
              platformName={paymentProvider} 
              payableDataId={invoice.invoice_id}
              relatedObjectType={invoice?.online_payment?.rel_object_type}
            >
              {<Payment />} {t('common:text.payment_form_pay', {amount: `${invoice.amount_pending.toLocaleString(currentLang.value)} €`,})}
            </PayButton>
          )
        }
      </>
    }
  ].filter(Boolean);

  const columnsMultipoint = [
    {
      title: t('common:text.invoices_invoice_number'),
      key: "num",
      computeValue: (invoice) => invoice.number
    }, {
      title: t('common:text.invoices_date'),
      key: "date",
      computeValue: (invoice) => dayjs(invoice.date).format("L")
    }, {
      title: t('common:text.invoices_address'),
      key: "address",
      computeValue: (invoice) => invoice.address_invoice_id	
    }, {
      title: t('common:text.invoices_import'),
      key: "import",
      computeValue: (invoice) => invoice.amount_total
    }, 
    // TODO: Implementar l'API per retornar aquestes dades
    // {
    //   title: t('common:text.invoices_pending'),
    //   key: "pending",
    //   computeValue: (invoice) => !invoice.paid && `${invoice.amount_pending.toLocaleString(currentLang.value)} €`
    // },
    {
      title: t('common:text.invoices_actions'),
      key: "actions",
      computeValue: (invoice) => <DownloadButton 
          invoiceId={invoice.id} 
          type={type}
          options={options}
        />
    }
  ]

  const columns = type === 'electricity' ? columnsElectricity : (type === 'multipoint' ? columnsMultipoint : []);

  const getInvoiceStatus = (item) => {
    if (item?.pending) {
      return <ErrorOutline style={{ color: '#FF0000' }} />
    }
  }

  return {
    invoices, columns, getInvoiceStatus, loaded
  }
}

export default useInvoicesTable;