import { Pagination, IPaginationRange } from "@gisce/oficina-virtual-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "@/store";
import { MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Settings from "@/settings";

// TODO: This component should bepart of an abstraction for a generic table

const settingsItemsPerPage = Settings?.contracts?.itemsPerPage ?? 10;

interface IContractsPagination {
  page: number;
  onItemsPaginationChange: (page: number, limit: number) => {};
  type: "electricity" | "gas";
}

const getPossibleItemsPerPage = () => {
  let possibleItemsPerPage = [5, 10, 25, 50, 100];
  if (settingsItemsPerPage && !possibleItemsPerPage.includes(settingsItemsPerPage)) {
    possibleItemsPerPage.push(settingsItemsPerPage);
    possibleItemsPerPage.sort((a, b) => { return a - b; });
  }
  return possibleItemsPerPage;
}

const ContractsPagination = ({
  page,
  onItemsPaginationChange,
  type // TODO: Use this when contracts are merged
}: IContractsPagination) => {
  const { t } = useTranslation();

  const {
    isFetching,
    total_items: itemsTotal = 0 
  } = useSelector((state: RootState) => state.contracts);
  
  const [itemsPerPage, setItemsPerPage] = useState(settingsItemsPerPage);
  const [currentPage, setCurrentPage] = useState(page);

  console.log("currentPage", currentPage);
  /**
   * Handles the page change event for the Pagination component.
   *
   * @param {IPaginationRange} range - The range of items to display.
   * @param {number} page - The page number to navigate to.
   * @param {boolean} outOfRange - Whether the target page is outside the valid range.
   *
   * @returns {void}
   */
  const handleChangePage = (
    range: IPaginationRange, 
    newPage: number, 
    outOfRange: boolean
  ) => {
    // Prevent unnecessary API calls for out-of-range pages
    if(!outOfRange) {
      onItemsPaginationChange(newPage, itemsPerPage);
    }
    setCurrentPage(newPage);
  }

  const handleItemsPerPageChange = (event: SelectChangeEvent) => {
    setItemsPerPage(event.target.value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  return ( itemsTotal > settingsItemsPerPage &&
    <Stack direction="row">
      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="center">
        <Pagination 
          itemsPerPage={itemsPerPage}
          itemsTotal={itemsTotal}
          onPageChange={handleChangePage}
          currentPage={currentPage}
          color="primary"
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
        {t("common:text.pagination_items_per_page")}
        <Select
          variant="standard"
          value={itemsPerPage}
          label="Items"
          onChange={handleItemsPerPageChange}
        >
          {getPossibleItemsPerPage().map((itemsPerPage, idx) => {
            return <MenuItem value={Number(itemsPerPage)} key={idx}>
              {Number(itemsPerPage)}
            </MenuItem>
          })}
        </Select>
      </Stack>
    </Stack>
  )
}

export default ContractsPagination;